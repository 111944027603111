Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
///userProfile///
exports.presonalinfo="Personal Information"
exports.name="Name"
exports.contact="Contact No."
exports.email="Email"
exports.nameError="Please enter name"
exports.emailError="Please enter email id"
exports.emailErrorValid="Email is invalid"
exports.hidden="hidden"
exports.phoneNumber="mobile number is not valid"
exports.placeHolderName="Enter your name"
exports.placeHolderEmail="Enter your email"
exports.placeHolderMobile="Enter your number"

exports.profile_icon="profile_icon"
exports.panH="PAN"
exports.changePasscode="Change Password"
exports.itrHistory="ITR History"
exports.transactionHistory="Transaction History"

exports.buttonUpdate="Update"
exports.buttonChange="Change Password"
exports.buttonClear="Clear"
exports.makeAnQuery="Make an Enquiry"
exports.fileItr="File ITR"
exports.primary="Primary"


exports.pan="Pan"
exports.fullName="Full Name"
exports.status="Status"
exports.current ="Current Password *"
exports.currentNew ="New Password *"
exports.confirm ="Confirm New Password *"


exports.serial="Serial no."
exports.date="Date"
exports.panDetails="PAN details"
exports.taxPayer="TAX payer"
exports.statusofData="Status"
exports.takeAction="Take action"

exports.transactionHistory="TransactionHistory"
exports.transactionId="Transaction ID"
exports.transactionDate="Transaction Date"
exports.transactionpanDetails="PAN details"
exports.assessmentYear="Assessment Year"
exports.Amount="Amount"

exports.profiledataGet="account_block/accounts"

exports.nameErrorUpdate="Name is required"
exports.phoneErrorUpdate="Phone Required"
exports.phonevalidationErrorUpdate="Min 6 to Max 11 digits only"
exports.passplaceHolder="Your Password"
exports.currentError="Please enter password"
exports.newError="Please enter new password"
exports.newValidationError="Min 8 characters with both upper and lower cases, atleast one digit and one special character required"
exports.confirmError="Please enter confirm password"
exports.confirmValidationError="Password and confirm Password not matched"
exports.UserProfileBasicBlock="UserProfileBasicBlock"
exports.ITRHistoryNavigate="ITRHistory"
exports.TransactionHistoryNavigate="TransactionHistory"
exports.authToken="authToken"
exports.profile_icon="profile_icon"
exports.noName="No Name"
exports.noNumber="No Number"
exports.userblockAPi="bx_block_profile/passwords"
exports.backendurlforImages="https://keshavchatbot-280223-ruby.b280223.dev.eastus.az.svc.builder.cafe"
exports.clickaway="clickaway"
exports.apiUpdateUserTypePatch = "PATCH";
exports.panCardEndpoint="account_block/pan_profiles"
exports.EmailAccountLoginBlock="EmailAccountLoginBlock"
exports.LandingPage="LandingPage"
exports.AddPANData="+ Add PAN"
exports.AddPANcard="Add PAN card"
exports.otpmessage="You will receive an SMS with OTP to verify your details"
exports.getotpButton="Get OTP"
exports.verifiedPan="Your PAN has been verified successfully"
exports.verifyButton="Verify"
exports.methodTypeApiDelUserProfile = "DELETE";



// Customizable Area End

