export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const profileimage = require("../assets/image_OnlinePage-amico.png");
export const profileIconimage = require("../assets/view_Oval.png");
export const pancard = require("../assets/pancard.png");
export const lock = require("../assets/lock.png");
export const hamburger = require("../assets/hamburger.png");
export const paper = require("../assets/paper.png");
export const edit = require("../assets/edit.png");
export const image_edit = require("../assets/image_edit.png");
export const Shield = require("../assets/successshield.png");
export const deleteIcon = require("../assets/delete_icon.png");







