import React from "react";
import AccountRegistrationController, {
  Props
} from "./AccountRegistarationController.web";
import {
  Container,
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Divider,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Toster from "../../../components/src/Toster";
import CustumCkeckBox from "../../../components/src/CustomCheckBox.web";
import TermsConditions from "../../termsconditions/src/TermsConditions.web";
import Google from "../../../components/src/GoogleLogin";
import { styled } from '@material-ui/styles';
import { BrandLogo } from "./assets";
import OTPInputAuth from "../../otp-input-confirmation/src/OTPInputAuth.web";
import PrivacyPolicy from "../../termsconditions/src/PrivacyPolicy.web";
// Customizable Area Start
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
// Customizable Area End
export default class AccountRegistrationBlock extends AccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start
  communInputField1 = (name: string, label: string, placeholder: string, type: string, handleShowHidePassword?: any,) => {
    let showEye = name === 'password' ? this.state.showPassword : this.state.showConfirmPassword
    let stateName = name === 'password' ? 'showPassword' : 'showConfirmPassword'

    let checkType = () => {
      if(name === "password"){
        return this.state.showPassword ? "text" : "password"
      }else if(name === "confirmPassword"){
        return this.state.showConfirmPassword ? "text" : "password"
      }
      return type;
    }

    return (
      <Box component="div" sx={{
        margin: "15px 0",
      }}>
        <Typography component="span" variant="caption" style={style.labels}>{label}</Typography>
        <br />
        <TextField
          name={name}
          placeholder={placeholder}
          style={{
            boxSizing: "border-box",
            margin: 0,
          }}
          className="input-signup"
          value={this.state.formData[name]}
          data-test-id={`${name}-id`}
          fullWidth
          onChange={(e: { target: { name: string, value: string } }) => this.handleChange(e)}
          margin="normal"
          variant="outlined"
          inputProps={{
            style: {
              height: "0.5rem",
              borderRadius: '5px',
              color: "#fff"
            }
          }}
          InputProps={{
            endAdornment:
              name !== "email" ? (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleShowHidePassword(stateName)}
                    data-test-id='btnTogglePassword'
                    aria-label="toggle password visibility"
                  >
                    {showEye ? <Visibility style={{color: "white"}} /> : <VisibilityOff style={{color: "white"}} />}
                  </IconButton>
                </InputAdornment>
              ) : null,
              style: {
                border: "1px solid white",
                color: "#fff",
                borderRadius: "5px",
              },
          }}
          type={checkType()}
        />
        <Typography variant="body2" component="div" style={style.error}>
          {this.state.errors[name]}
        </Typography>
      </Box>
    );
  };

  renderCheckbox = () => {
    return (
      <Box component="div" display="flex">
        <CustumCkeckBox
          label=""
          onChangeValue={this.handleCheckbox}
          isChecked={this.state.isChecked}
          color={"acafb3"}
        />
        <Typography data-test-id="forget-password-id" variant="body2" style={style.terms1} >
          By continuing you indicate that you agree the <Typography onClick={this.handleTerms} component="span" variant="body2" style={{ color: '#279e66' }} data-test-id="terms-id">Terms of service</Typography> and <Typography onClick={this.handlePrivacy} style={{ color: '#279e66' }} variant="body2" component="span" data-test-id="privacy-id">Privacy Policy</Typography>.
        </Typography>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ImageWrapper disableGutters maxWidth={false} style={{ ...style.fullWidth }}>
        <Toster {...this.state.toast} handleTostClose={this.handleTostClose} />
        <TermsConditions
          open={this.state.termsOpen}
          handleClose={this.handleTerms}
          navigation=""
          id=""
          description={this.state.termsDescription}
        />
        <PrivacyPolicy open={this.state.privacyOpen}
          navigation=""
          id=""
          handleClose={this.handlePrivacy}
          description={this.state.privacyDescription}
        />
        <Grid container style={style.fullWidth}>
          <Grid item lg={3} sm={1} xl={3} md={3}></Grid>
          <Grid item lg={4} sm={10} xl={4} md={5} style={{overflowY: 'auto',...style.form1} as React.CSSProperties }>
            <Box component="div" sx={{
              margin: "auto", width: "75%"
            }}>
              <Box component="div" display="flex" flexDirection="column">
                <Typography component="h1" variant="h4" style={{
                  margin: "25px 0 20px 0",
                  textAlign: "center",
                }}><img src={BrandLogo} alt="pocket tax" onClick={this.navigatesignupToDashboard} style={{cursor:"pointer",width:"150px"}}/></Typography>
                <Typography component="h5" variant="h5" style={style.white1}>Welcome!</Typography>
                <Typography variant="subtitle1" style={style.titlebody1}>Create your account</Typography>
              </Box>
              <Box component="div">
                {this.communInputField1("email", "Email", "Your email", "email")}
                {this.communInputField1("password", "Password", "Your Password", "password", this.handleShowHidePassword)}
                {this.communInputField1(
                  "confirmPassword",
                  "Confirm Password",
                  "Your Password",
                  "password",
                  this.handleShowHidePassword
                )}
                {this.renderCheckbox()}
                <Button variant="contained"
                  style={{ ...style.loginBtn1 }}
                  onClick={this.handleSubmit}
                  data-test-id="submit-id">Continue</Button>
                <Box component="div" display="flex" justifyContent="center" alignItems="center">
                  <Divider style={style.dividerLines} />
                  <Typography data-test-id="forget-password-id" variant="body2" component='span' style={style.dividerText1} >
                    Or
                  </Typography>
                  <Divider style={style.dividerLines} />
                </Box>
                <Box component="div" display="flex" justifyContent="center" alignItems="center" sx={style.google1}>
                  <Google handleGoogleLogin={this.handleGoogleLogin} />
                </Box>
                <Typography align="center" style={style.signUp1}>
                  Already have an account?{" "}
                  <Link to="/login" style={{ color: '#279e66' , textDecoration:"none" }}>
                    Login
                  </Link>{" "}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item lg={5} sm={1} xl={5} md={4} ></Grid>
        </Grid>
        <OTPInputAuth otpOpen={this.state.otpOpen} handleOtpClose={this.handleOtpClose} email={this.state.formData.email} navigation={this.props.navigation} id='' />
      </ImageWrapper>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
const ImageWrapper = styled(Container)({
  "& .MuiOutlinedInput-root": {
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline":{
        border: '1px solid white',
        borderRadius:"0"
      }
    }
  },
  backgroundImage: `url(${require("../assets/Tree.png")}),url(${require("../assets/TableGuy.png")}),url(${require("../assets/Background.png")})`,
  backgroundSize: "120px 200px, 270px 400px,100% 100%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "200px 90%, 75% 90%, center",
  overflowY: "hidden",
  "@media(max-width:1280px)": {
    backgroundPosition: "200px 90%, 90% 90%, center",
  },
  "@media(max-width:959px)": {
    backgroundImage: `url(${require("../assets/Background.png")})`,
    backgroundSize: "100% 100%",
    backgroundPosition: "center",
  },
  "& .input-signup": {
    paddingTop:"10px",
    paddingBottom:"2px",
    "& .MuiOutlinedInput-input": {
      padding: "12.5px 14px",
      fontSize:"12px",fontFamily:"Nunito",
    },
    "& .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: '5px',
        border: '1px solid white',
      },
      "&:hover .MuiOutlinedInput-notchedOutline": {
        borderRadius: '5px',
        border: '1px solid white',
      },
    },
  },
})

const style = {
  white1: {
    color: "#fff",
    fontSize: "2.4rem",fontFamily:"Nunito",
  },
  fullWidth: {
    width: "100%",
    height: "100%"
  },
  titlebody1: {
    color: "#acafb3", margin: "0.35rem 0 0 0", fontSize: "1.1rem",fontFamily:"Nunito",
  },
  loginBtn1: {
    backgroundColor: "#2b664b",
    color: "#fff",
    width: "100%",
    margin: '0.65rem 0px'
  },
  form1: {
    backgroundColor: "#1b222e",
    margin: "auto 0",
    borderRadius: "0.95rem",
    height: '98%',
    scrollbarWidth:"thin",
    scrollbarColor:"#2b664b #f1f1f1"
  },
  labels: {
    color: "white", fontSize: "1rem", margin: "5px 0",fontWeight:400,padding:"3px 0px",fontFamily:"Nunito",
  },
  terms1: {
    color: "white", cursor: 'pointer',fontWeight:600,fontFamily:"Nunito",fontSize: "14px",
  },
  dividerText1: {
    color: '#acafb3', margin: "0.8rem 1.18rem"
  },
  dividerLines: {
    width: "30%", backgroundColor: "#acafb3"
  },
  error: {
    color: "red",
    fontSize: "0.75rem",fontFamily:"Nunito",
    marginTop: "0.2rem"
  },
  signUp1: {
    color: '#cfd1d4', fontSize: "14px", padding: '0.65rem 0px 1.1rem 0px',fontFamily:"Nunito",
  },
  google1: {
    width: "80%", margin: "auto", cursor: 'pointer'
  }
};
// Customizable Area End
