import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getNavigationMessage } from "../../../components/src/Navigation.web"
import { profileIconimage } from "./assets";
import { setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  enableEditMode: boolean;
  name: string;
  phoneNumber: string;
  email: string;
  editId: string
  errors: {
    name: string;
    email: string;
    phoneNumber: string;
  };
  errorsPwd: {
    currentPassword: string;
    newPassword: string;
    confirnNewPassword: string;
  }
  enablePanEditMode: boolean;
  checkedB: boolean;
  enablePasswordEditMode: boolean;
  currentPassword: string;
  newPassword: string;
  confirnNewPassword: string;
  enablePasswordField: boolean;
  enableNewPasswordField: boolean;
  enableConfirmPasswordField: boolean;
  open: boolean;
  profilePhoto: File | null;
  previewSource: string;
  profilePicture: string
  statusOpen: boolean
  statusOpenError: boolean
  snakbarMessage: string;
  openPanCad: boolean;
  panOtpError: string;
  panOtp: string;
  second: number;
  isOtpVerified: boolean;
  panOtpOpen: boolean;
  panDetails: {
    [key: string]: string;
  };
  panDetailsError: {
    [key: string]: string;
  };
  userPancards: any;
  activeSwitch: string | number;
  count: number;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class UserProfileBasicController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userProfileCall: string = ""
  userProfileUpdate: string = ""
  fileInput: any;
  userProfileUpdatepwd: string = ""
  navigation: any;
  pancardgetprofile: string = ""
  pancardpostprofile: string = ""
  pancardpostprofileSwitch:string=""
  pancardDeleteprofile:string=""
  timer: number | ReturnType<typeof setInterval> = 0;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      enableEditMode: false,
      name: "",
      phoneNumber: "",
      email: "",
      editId: "",
      errors: {
        name: "",
        email: "",
        phoneNumber: ""
      },
      enablePanEditMode: false,
      checkedB: false,
      enablePasswordEditMode: false,
      currentPassword: "",
      newPassword: "",
      confirnNewPassword: "",
      errorsPwd: {
        currentPassword: "",
        newPassword: "",
        confirnNewPassword: "",
      },
      enablePasswordField: false,
      enableNewPasswordField: false,
      enableConfirmPasswordField: false,
      open: false,
      profilePhoto: null,
      previewSource: "",
      profilePicture: "",
      statusOpen: false,
      statusOpenError: false,
      snakbarMessage: "",
      openPanCad: false,
      panOtpError: "",
      panOtp: "",
      second: 59,
      panOtpOpen: false,
      isOtpVerified: false,
      panDetails: {
        name: "",
        panNo: ""
      },
      panDetailsError: {
        name: "",
        panNo: ""
      },
      userPancards: [],
      activeSwitch: "",
      count: 0,
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    const responseData = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const successMessage = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (this.userProfileCall === responseData) {
      this.handleUserProfileCall(successMessage);
    } else if (this.userProfileUpdate === responseData) {
      this.handleUserProfileUpdate(successMessage);
    } else if (this.userProfileUpdatepwd === responseData) {
      this.handleUserProfileUpdatePassword(successMessage);
    } else if (this.pancardgetprofile === responseData) {
      this.handlePancardGetProfile(successMessage);
    } else if (this.pancardpostprofile === responseData) {
      this.handlePancardPostProfile(successMessage);
    } else if (this.pancardpostprofileSwitch === responseData) {
      this.handlePancardSwitchProfile(successMessage);
    }
    else if (this.pancardDeleteprofile === responseData) {
      this.handlePancardDeleteProfile(successMessage);
    }

    // Customizable Area End
  }

  handleUserProfileCall(apiResponse: any) {
    if (apiResponse && apiResponse.data) {
      localStorage.setItem("username", apiResponse.data.attributes.first_name);
      this.setState({
        name: apiResponse.data.attributes.first_name,
        phoneNumber: apiResponse.data.attributes.phone_number,
        email: apiResponse.data.attributes.email,
        profilePicture: apiResponse.data.attributes.profile_picture,
        editId: apiResponse.data.id
      });
    } else {
      this.send(getNavigationMessage(configJSON.EmailAccountLoginBlock, this.props));
    }
  }

  handleUserProfileUpdate(apiResponse: any) {
    if (apiResponse && apiResponse.data) {
      this.setState({ enableEditMode: false });
      this.userProfile();
    }
  }

  handleUserProfileUpdatePassword(apiResponse: any) {
    if (apiResponse && apiResponse.change_password) {
      this.setState({
        statusOpen: true,
        snakbarMessage: apiResponse.change_password,
        currentPassword: "",
        newPassword: "",
        confirnNewPassword: ""
      });
    } else if(apiResponse && apiResponse.error && apiResponse.error[0] === "Check your current_password"){
      this.setState({count: this.state.count + 1}, () => {
        this.userprofilePassword();
      })
      this.setState({count: 0})
    } else {
      this.setState({ statusOpenError: true, snakbarMessage: apiResponse?.error[0] });
    }
  }

  handlePancardGetProfile(apiResponse: any) {
    if (apiResponse && apiResponse.data) {
      this.setState({ userPancards: apiResponse.data, checkedB: apiResponse.data.is_primary });
    }
  }

  handlePancardPostProfile(apiResponse: any) {
    if (apiResponse && apiResponse.message == "Pan profile created with associated PanUserProfiles and AssessmentYears") {
      this.userProfilePanCard();
      this.setState({ panDetails: { name: "", panNo: "" },isOtpVerified:true,panOtp:""});
    }else{
      this.setState({ statusOpenError: true, snakbarMessage: apiResponse.message , panOtpOpen:false,panDetails:{},panOtp:""  });
    }
  }
  handlePancardSwitchProfile(apiResponse: any) {
    if (apiResponse && apiResponse.data) {
      this.userProfilePanCard();
      this.setState({ statusOpen: true, statusOpenError: false, snakbarMessage: apiResponse.meta.message });

    }
  }
  handlePancardDeleteProfile(apiResponse: any){
    if (apiResponse && apiResponse.success ) {
      let message = apiResponse.success.message === "Pan Profile and associated Pan User Profiles deleted successfully."
      ? "Associated Pan Profile is deleted successfully." : apiResponse.success.message;

      this.setState({ statusOpen: true, snakbarMessage: message });
      this.userProfilePanCard();
    }else{
      this.setState({ statusOpenError: true, snakbarMessage: apiResponse.error.message });
    }
  }

  async componentDidMount() {
    // Customizable Area Start
    this.userProfile()
    this.userProfilePanCard()
    // Customizable Area End
  }
  // Customizable Area Start

  ///userprofiledtails Get///

  userProfile = () => {

    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      "token": localStorage.getItem(configJSON.authToken)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userProfileCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profiledataGet
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  ///userprofiledtails///
  ///userprofile Update post ///
  updateUserDetails = () => {
    const headers = {
      "token": localStorage.getItem(configJSON.authToken)
    };

    let formdata = new FormData();
    formdata.append("data[first_name]", this.state.name);
    formdata.append("data[phone_number]", this.state.phoneNumber);
    formdata.append("data[email]", this.state.email);
    if (this.state.profilePhoto) {
      formdata.append("data[profile_picture]", this.state.profilePhoto);
    }



    const getpasswordfrom = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userProfileUpdate = getpasswordfrom.messageId;

    getpasswordfrom.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profiledataGet + "/" + this.state.editId
    );

    getpasswordfrom.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getpasswordfrom.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    getpasswordfrom.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType

    );
    runEngine.sendMessage(getpasswordfrom.id, getpasswordfrom);
  }
  ///userprofile Update post ///
  ///userprofile changepwd post ///
  updatePassword = () => {
    const headers = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      "token": localStorage.getItem(configJSON.authToken)
    };

    const httpBody = {
      data: {
        current_password: this.state.currentPassword,
        new_password: this.state.newPassword,
        password_confirmation: this.state.confirnNewPassword

      },
    };


    const getpasswordfrom = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userProfileUpdatepwd = getpasswordfrom.messageId;

    getpasswordfrom.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userblockAPi + "/" + this.state.editId
    );

    getpasswordfrom.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getpasswordfrom.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getpasswordfrom.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserTypePatch

    );
    runEngine.sendMessage(getpasswordfrom.id, getpasswordfrom);
  }
  ///userprofile changepwd post ///

  ///pancard get details ///
  userProfilePanCard = () => {

    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      "token": localStorage.getItem(configJSON.authToken)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.pancardgetprofile = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.panCardEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  ///pancard get details///
  ///pancard post details ///
  userProfilePanCardPost = () => {

    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      "token": localStorage.getItem(configJSON.authToken)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.pancardpostprofile = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.panCardEndpoint}?pan_number=${this.state.panDetails.panNo}&name=${this.state.panDetails.name}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  ///pancard post details///
  ///pancard switch  get details ///
  userProfilePanCardPostSwitch = (id: string | number, assessmentYear: string) => {

    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      "token": localStorage.getItem(configJSON.authToken)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.pancardpostprofileSwitch = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.panCardEndpoint}/set_primary_pan?pan_profile=${id}&assessment_year=${assessmentYear}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiUpdateUserType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  ///pancard get details///
  ///pancard delete ////
  pancarddelete = async(id:string) => {

    const header = {
      "Content-Type": configJSON.contentTypeApiUpdateUser,
      "token": localStorage.getItem(configJSON.authToken)
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.pancardDeleteprofile = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.panCardEndpoint}/delete_pan_user?pan_profile=${parseInt(id)} `
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.methodTypeApiDelUserProfile
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }
  ///pancard delete ///
  handleMessageClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === configJSON.clickaway) {
      return;
    }

    this.setState({ statusOpen: false, statusOpenError: false })
  };
  handleIconClick = () => {
    this.fileInput.click();
  };
  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      this.setState({
        profilePhoto: file,
      });
      this.previewFile(file);
    }
  };
  previewFile = (file: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      const previewSource = reader.result as string;
      this.setState({ previewSource });
    };
  };

  handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value.replace(/^\s+/, "");
    value = value.replace(/\s{2,}/g, ' ');
    const alphabetsOnly = /^[A-Za-z ]+$/;
    if (value.length <= 30 && (value === "" || alphabetsOnly.test(value))) {
      this.setState({ name: value });
    }
  }
  handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) ) {
      this.setState({ phoneNumber: value });
    }
  }
  
  
  handleFocusEdit = (event: { target: { name: string } }) => {
    const { name } = event.target;
    this.setState(prevState => ({
      errors: {
        ...prevState.errors,
        [name]: ""
      }
    }));
  };
  clearPassword = () => {
    this.setState({ currentPassword: "", confirnNewPassword: "", newPassword: "" })
  }
  editProfileEnable = () => {
    this.setState({ enableEditMode: true });
  };
  panCardHandle = () => {
    this.setState({ enablePanEditMode: !this.state.enablePanEditMode });
  };
  handleChangeChecked = (id: string | number, assessmentYear: string) => {
    this.setState({ activeSwitch: id });
    this.userProfilePanCardPostSwitch(id, assessmentYear)

  };
  handlePasswordChange = () => {
    this.setState({
      enablePasswordEditMode: !this.state.enablePasswordEditMode
    });
  };

  handleClickShowPassword = () => {
    this.setState({
      enablePasswordField: !this.state.enablePasswordField,
    });
  };
  handleClickShowPasswordNew = () => {
    this.setState({
      enableNewPasswordField: !this.state.enableNewPasswordField,
    });
  };
  handleClickShowPasswordConfirm = () => {
    this.setState({
      enableConfirmPasswordField: !this.state.enableConfirmPasswordField,
    });
  };
  modalOpen = () => {
    this.setState({ open: true })
  }
  handleModelClose = () => {
    this.setState({ open: false })
  }
  navigateToItr = () => {
    setStorageData('ItrHistoryCameFrom', 'UserProfileBasicBlock')
    this.send(getNavigationMessage(configJSON.ITRHistoryNavigate, this.props))
  };
  navigateToTransaction = () => { this.send(getNavigationMessage(configJSON.TransactionHistoryNavigate, this.props)) };
  navigatetoLandingpage = () => {
    this.props.navigation.history.push(`/Menu/Dashboard`)
  }
  handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  handleFocus = (event: { target: { name: string } }) => {
    const { name } = event.target;
    this.setState(prevState => ({
      errorsPwd: {
        ...prevState.errorsPwd,
        [name]: ""
      }
    }));
  };

  handleProfileUpdate = () => {
    const regexPhone = /^\d{6,11}$/;
    let isFormvalid = true
    if (this.state.name == "" || this.state.name == null) {
      this.state.errors["name"] = configJSON.nameErrorUpdate
      isFormvalid = false
    }
    if (this.state.phoneNumber === '') {
      this.state.errors['phoneNumber'] = configJSON.phoneErrorUpdate
      isFormvalid = false
    }
    else if (!regexPhone.test(this.state.phoneNumber)) {
      this.state.errors['phoneNumber'] = configJSON.phonevalidationErrorUpdate
      isFormvalid = false
    }
    return isFormvalid
  }
  handleProfilePasswordUpdate = () => {
    const passwordRegx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+=`~<>?/.,;'":[|}{\\-]).{8,}$/;
    let isFormvalid = true
    if (this.state.currentPassword == "") {
      this.state.errorsPwd["currentPassword"] = configJSON.currentError
      isFormvalid = false
    }
    if(this.state.count > 0){
      this.state.errorsPwd["currentPassword"] = 'Incorrect current password'
      isFormvalid = false
    }
    if (this.state.newPassword === '') {
      this.state.errorsPwd['newPassword'] = configJSON.newError
      isFormvalid = false
    }
    else if (!passwordRegx.test(this.state.newPassword)) {
      this.state.errorsPwd['newPassword'] = configJSON.newValidationError
      isFormvalid = false
    }
    if (this.state.confirnNewPassword === '') {
      this.state.errorsPwd['confirnNewPassword'] = configJSON.confirmError
      isFormvalid = false
    }
    else if (this.state.confirnNewPassword != this.state.newPassword) {
      this.state.errorsPwd['confirnNewPassword'] = configJSON.confirmValidationError
      isFormvalid = false
    }
    return isFormvalid
  }
  userprofileUpdate = () => {
    if (this.handleProfileUpdate()) {
      this.updateUserDetails()
    } else {
      this.setState({
        errors: this.state.errors
      })
    }
  }
  userprofilePassword = () => {
    if (this.handleProfilePasswordUpdate()) {
      this.updatePassword()
    } else {
      this.setState({
        errorsPwd: this.state.errorsPwd
      })
    }
  }
  handleAddpanDetails = () => {
    this.setState({ openPanCad: !this.state.openPanCad })
  }
  handleclosePandetails = () => {
    this.setState({ openPanCad: false , panDetails:{},panOtp:""})
  }
  handleOtpChange = (e: string) => {
    this.setState({ panOtp: e });
  }
  handleVerifyOtp = () => {
    this.userProfilePanCardPost()
  }

  handleResendOtp = () => {
    this.setState({ panOtp: "", second: 60 })
  }
  closePanModelUserprofile = (_event?: {}, reason?: "escapeKeyDown" | "backdropClick") => {

    if (reason && reason === "backdropClick") {
      this.setState({ panOtp: ""})
    }
    else if (reason === "escapeKeyDown") {
      this.setState({
        panOtpOpen: false,
        panOtp: "",
      
      });
      clearInterval(this.timer);
    }
    else {
      this.setState({
        panOtpOpen: false,

        panOtp:""
      })

      clearInterval(this.timer)
    }

    this.setState({ isOtpVerified: false, panOtp: "" })
  }
  handleNameChangeInput = (e: { target: { name: string, value: string } }) => {
    let val = e.target.value.replace(/^\s+/, "");
    val = val.replace(/\s{2,}/g, ' ');
    if (!/^[a-zA-Z ]*$/.test(e.target.value) || e.target.value.length > 35) {
        return;
    }
    this.setState({
      panDetails: { ...this.state.panDetails, [e.target.name]: val },
      panDetailsError: { ...this.state.panDetailsError, [e.target.name]: "" }
    })
  }
  handlePanChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let val = e.target.value.replace(/^\s+/, "");
    val = val.replace(/\s{2,}/g, ' ');
    if (!/^[a-zA-Z0-9]*$/.test(e.target.value) || e.target.value.length > 10) {
        return;
    }
    this.setState({
      panDetails: { ...this.state.panDetails, [e.target.name]: val },
      panDetailsError: { ...this.state.panDetailsError, [e.target.name]: "" }
    })
  }
  handlepanValidUserProfile=(pan:string)=>{
    let regex = /^[A-Z]{5}\d{4}[A-Z]$/;
    if (regex.test(pan)) {
        return true;
    } 
    else{
       return false
    }
}
  handlePanDetailsSubmitcall = () => {
    let main= this.handlepanValidUserProfile(this.state.panDetails.panNo)
    const error = {
      panNo: main ? "" : "Incorrect PAN",
      name: this.state.panDetails.name.length >= 2 ? "" : "Enter valid name"
  };
    this.setState({ panDetailsError: error })
    let temp = [];
    for (const [key, value] of Object.entries(error)) {
      key && temp.push(value === "");
    }
    if (!temp.includes(false)) {
      this.setState({ second: 60, panOtpOpen: true, openPanCad: false  })
      this.timer = setInterval(() => {
        if (this.state.second > 0) {
          this.setState({ second: this.state.second - 1 });
        }
      }, 1000);
    }
  }
  handlepanDelete=(id:string)=>{
    this.pancarddelete(id)
  }

  editProfilePictureCondition = () => {      
    if(this.state.profilePhoto){
      return this.state.previewSource
    } else if(this.state.profilePicture){
      return this.state.profilePicture;
    } 
    return profileIconimage;
  }
  // Customizable Area End
}
