import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
} from "@material-ui/core";
import { profileimage } from "../../blocks/user-profile-basic/src/assets"
import { withStyles } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';


interface MyComponentProps {
    title: string;
    onClick:any;
}

export class BackgroundHeader extends React.Component<MyComponentProps> {

    render() {
        const { classes }: any = this.props;
        return (
            <Box className={classes.background}>
                <Box className={classes.headerContainer}>
                    <Box className={classes.subHeaderContainer}>
                        <ChevronLeftIcon fontSize="large" className={classes.arrowColor}  onClick={this.props.onClick}/>
                        <Typography variant="h5" className={classes.textsize} > {this.props.title}</Typography>
                    </Box>
                    <Box >
                    </Box>
                    <img src={profileimage} alt="background_profile" className={classes.imageStyle} />
                </Box>
            </Box>
        )
    }


}
const styles = {
    background: {
        background: "radial-gradient(100% 100% at 100% 64%, #77AE93 0%, #8CAF9D 30.08%, #27533F 100%)",
        height: "22.639vw",
        position: "relative" as const

    },
    headerContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    subHeaderContainer: {
        display: "flex",
        alignItems: "center",
        marginLeft: "4em",
          "@media(max-width:950px)": {
            marginLeft: "3em",
          },
          "@media(max-width:790px)": {
            marginLeft: "2em",
          },
          "@media(max-width:650px)": {
            marginLeft: "1em",
          },
    },

    imageStyle: {
        width: '17.361vw',
        height:"17.852vw",
        margin: "1.875em  4em 0em 0em",
        "@media(max-width:1029px)": {
            margin: "1.875em  3em 0em 0em",
          },
          "@media(max-width:950px)": {
            margin: "1.875em  2em 0em 0em",
          },
          "@media(max-width:790px)": {
            margin: "1.875em  1em 0em 0em",
          },
          "@media(max-width:650px)": {
            margin: "0.5em  1em 0em 0em",
          },
    },
    arrowColor: {
        cursor: "pointer",
        color: "white",
        fontSize:"4.5vw"
    },
    textsize:{
        color: "white",
        fontSize:"1.667vw",
        
    }
};
export default withStyles(styles)(BackgroundHeader);

// Customizable Area End