import React from "react";
// Customizable Area Start
import {
    Box,
    Radio,
    withStyles,
    styled,
    MenuItem,
    Select,
    RadioGroup,
    FormControlLabel,
} from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AssessmenttestController, {
    Props,
} from "./AssessmenttestController";
import MainHeader from "../../../components/src/MainHeader.web";
import CustomInput from "../../../components/src/CustomInput.web";
// Customizable Area End

export default class RetirementCalc extends AssessmenttestController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    customInput = (heading: string, name: string, type: string, onChange: any, value: string | number, disabled: boolean) => {
        return this.customBox(
            <>
                <h3>{heading}</h3>
                <CustomInput
                    type={type}
                    name={name}
                    onChange={onChange}
                    placeholder={disabled ? "" : "Enter"}
                    value={value}
                    data-test-id={name}
                    disabled={disabled}
                />
            </>
        )
    }

    customBox = (input: any, minHeight: string = "") => {
        return (
            <div style={{ marginBottom: '10px', border: '1px solid #d3d3d3', padding: '2px 35px', width: '80%', height: minHeight == "" ? '50px' : "", minHeight: minHeight, display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderRadius: '4px', }}>
                {input}
            </div>
        )
    }

    renderGratuity = () => {
        return (
            <>
                {this.customBox(
                    <>
                        <h3>Assessment Year</h3>
                        <StyledSelect
                            data-test-id="assessment2"
                            displayEmpty
                            IconComponent={arrowDownIcon}
                            name="AssessmentYear"
                            MenuProps={{disableScrollLock:true}}
                            disableUnderline
                            style={{ color: 'grey', }}
                            value={this.state.assessmentYear}
                            onChange={this.handleChangeSelectDropDown}
                            renderValue={this.state.assessmentYear !== "" ? undefined : () => "Select"}
                        >

                            <MenuItem className='menuItems' value={"2022-23"}>2022-23</MenuItem>
                            <MenuItem className='menuItems' value={"2023-24"}>2023-24</MenuItem>
                            <MenuItem className='menuItems' value={"2024-25"}>2024-25</MenuItem>
                        </StyledSelect>
                    </>
                )}
                {this.customBox(
                    <>
                        <h3>Type of employer</h3>
                        <StyledSelect
                            MenuProps={{disableScrollLock:true}}
                            style={{ color: 'grey', }}
                            name="typeOfEmployer"
                            disableUnderline
                            displayEmpty
                            data-test-id="typeOfEmployer"
                            value={this.state.typeOfEmployer}
                            renderValue={this.state.typeOfEmployer !== "" ? undefined : () => "Select"}
                            onChange={this.handleChangeTypeOfEmployer}
                            IconComponent={arrowDownIcon}
                        >

                            <MenuItem className='menuItems' value={"ABC"}>ABC</MenuItem>
                            <MenuItem className='menuItems' value={"JKL"}>EFG</MenuItem>
                            <MenuItem className='menuItems' value={"XYZ"}>XYZ</MenuItem>
                        </StyledSelect>
                    </>
                )}
                {this.customInput("Gratuity received", "gratuityReceived", "text", this.handleHRA_Change2, this.state.gratuityReceived, false)}
                {this.customInput("Exempted gratuity", "COI", "text", this.handleHRA_Change2, this.state.COI, true)}
                {this.customInput("Taxable gratuity", "assessedTax", "text", this.handleHRA_Change2, this.state.assessedTax, true)}
            </>
        )
    }

    renderCommutedPensionCalc = () => {
        return (
            <>
                {this.customBox(
                    <>
                        <h3>Select Employer</h3>
                        <StyledSelect
                            style={{ color: 'grey', }}
                            displayEmpty
                            data-test-id="commutedEmployerType"
                            IconComponent={arrowDownIcon}
                            name="commutedEmployerType"
                            disableUnderline
                            MenuProps={{disableScrollLock:true}}
                            renderValue={this.state.commutedEmployerType !== "" ? undefined : () => "Select"}
                            value={this.state.commutedEmployerType}
                            onChange={this.handleChangeCommutedEmployerType}
                        >

                            <MenuItem className='menuItems' value={"Government"}>Government</MenuItem>
                            <MenuItem className='menuItems' value={"Other"}>Other</MenuItem>
                        </StyledSelect>
                    </>
                )}
                {this.customInput("Amount of pension", "gratuityReceived", "text", this.handleHRA_Change2, this.state.gratuityReceived, false)}
                {this.state.commutedEmployerType === "Other" && this.customBox(
                    <>
                        <h3>Whether Gratuity is received?</h3>
                        <RadioGroup row style={webStyle.radioGroupWrapper} name="period_of_holding" onChange={(event, value) => this.handleRadioChangePeriod(value, "periodOfHolding")} value={this.state.periodOfHolding} data-test-id="isGratuityReceived">
                            <FormControlLabel style={webStyle.marginRight} value="Yes" control={<StyledRadio />} label="Yes" />
                            <FormControlLabel style={webStyle.marginRightNone} value="No" control={<StyledRadio />} label="No" />
                        </RadioGroup>
                    </>
                )}
                {this.customInput("Exempted amount", "COI", "text", this.handleHRA_Change2, this.state.COI, false)}
                {this.customInput("Taxable amount", "assessedTax", "text", this.handleHRA_Change2, this.state.assessedTax, true)}
            </>
        )
    }

    renderEncashmentCalc = () => {
        return (
            <>
                {this.customBox(
                    <>
                        <h3>Assessment Year</h3>
                        <StyledSelect
                            style={{ color: 'grey', }}
                            displayEmpty
                            data-test-id="assessment2"
                            IconComponent={arrowDownIcon}
                            name="AssessmentYear"
                            disableUnderline
                            MenuProps={{disableScrollLock:true}}
                            renderValue={this.state.assessmentYear !== "" ? undefined : () => "Select"}
                            value={this.state.assessmentYear}
                            onChange={this.handleChangeSelectDropDown}
                        >

                            <MenuItem className='menuItems' value={"2024-25"}>2024-25</MenuItem>
                            <MenuItem className='menuItems' value={"2023-24"}>2023-24</MenuItem>
                            <MenuItem className='menuItems' value={"2022-23"}>2022-23</MenuItem>
                        </StyledSelect>
                    </>
                )}
                {this.customBox(
                    <>
                        <h3>Type of employer</h3>
                        <StyledSelect
                            disableUnderline
                            MenuProps={{disableScrollLock:true}}
                            name="typeOfEmployer"
                            style={{ color: 'grey', }}
                            data-test-id="typeOfEmployer"
                            value={this.state.typeOfEmployer}
                            onChange={this.handleChangeTypeOfEmployer}
                            renderValue={this.state.typeOfEmployer !== "" ? undefined : () => "Select"}
                            IconComponent={arrowDownIcon}
                            displayEmpty
                        >
                            <MenuItem className='menuItems' value={"XYZ"}>XYZ</MenuItem>
                            <MenuItem className='menuItems' value={"ABC"}>ABC</MenuItem>
                            <MenuItem className='menuItems' value={"JKL"}>EFG</MenuItem>
                        </StyledSelect>
                    </>
                )}
                {this.customInput("Taxable Leave Encashment", "COI", "text", this.handleHRA_Change2, this.state.COI, true)}
            </>
        )
    }

    renderInfoCalculator = (text: string) => {
        return (
            <>
                <Box style={webStyle.infoCalcBox}>
                    {this.formatTextWithLines(text)}
                </Box>
            </>
        )
    }

    formatTextWithLines = (inputText: string, marker = '|BREAK|') => {
        const lines = inputText.split(marker);
        return lines.map((line, index) => (
            <p key={index}>{line}</p>
        ));
    }

    renderPage = () => {
        let heading = "";
        let renderComponent;

        const RetrenchmentCompensationText = `Retrenchment compensation received by a workman under the Industrial Disputes Act, 1947 or any other Act or Rules is exempt subject to following limits:
        
            (i) Compensation calculated @ fifteen days average pay for every completed year of continuous service or part thereof in excess of 6 months.
            
            (ii) The above is further subject to an overall limit of Rs.5,00,000 for retrenchment on or after 1.1.1997 (Notification No. 10969 dated 25.6.99).`;

        const VoluntaryRetirementCompensationText = `(i) Payment received by an employee of the following at the time of voluntary retirement, or termination of service is exempt to the extent of Rs. 5 Lakh:

            (a) Public Sector Company.
            (b) Any other company.
            (c) Authority established under State, Central or Provincial Act
            (d) Local Authority.
            (e) Co-operative Societies, Universities, IITs and Notified Institutes of Management.
            (f)  Any State Government or the Central Government.

            (ii) The voluntary retirement Scheme under which the payment is being made must be framed in accordance with the guidelines prescribed in Rule 2BA of Income Tax Rules. In case of a company other than a public sector company and a co-operative society, such scheme must be approved by the Chief Commissioner/Director General of Income-tax. However, such approval is not necessary from A.Y. 2001- 2002 onwards.

            (iii) Where exemption has been allowed under above section for any assessment year, no exemption shall be allowed in relation to any other assessment year. Further, where any relief u/s 89 for any assessment year in respect of any amount received or receivable or voluntary retirement or termination of service has been allowed, no exemption under this clause shall be allowed for any assessment year.`;

        const ProvidentFundText = `(Any payment received from a Provident Fund, (i.e. to which the Provident Fund Act, 1925 applies) is exempt. Any payment from any other provident fund notified by the Central Govt. is also exempt. The Public Provident Fund(PPF) established under the PPF Scheme, 1968 has been notified for this purpose. Besides the above, the accumulated balance due and becoming payable to an employee participating in a Recognised Provident Fund is also exempt to the extent provided in Rule 8 of Part A of the Fourth Schedule of the Income Tax Act.`;

        const SuperannuationFundText = `Payment from an Approved Superannuation Fund will be exempt provided the payment is made in the circumstances specified in the section viz. death, retirement and incapacitation.`;

        switch (this.state.capitalGainType) {
            case "Gratuity":
                heading = "Gratuity Calculator";
                renderComponent = this.renderGratuity();
                break;
            case "CommutedPension":
                heading = "Commuted Pension Calculator";
                renderComponent = this.renderCommutedPensionCalc();
                break;
            case "LeaveEncashment":
                heading = "Leave Encashment Calculator";
                renderComponent = this.renderEncashmentCalc();
                break;
            case "RetrenchmentCompensation":
                heading = "Retrenchment Compensation";
                renderComponent = this.renderInfoCalculator(RetrenchmentCompensationText);
                break;
            case "VoluntaryRetirementCompensation":
                heading = "Voluntary Retirement Compensation";
                renderComponent = this.renderInfoCalculator(VoluntaryRetirementCompensationText);
                break;
            case "ProvidentFund":
                heading = "Provident Fund";
                renderComponent = this.renderInfoCalculator(ProvidentFundText);
                break;
            case "SuperannuationFund":
                heading = "Superannuation Fund";
                renderComponent = this.renderInfoCalculator(SuperannuationFundText);
                break;
        }
        return (
            <Box style={webStyle.mainbox1} >
                <div style={webStyle.div2}>
                    <div style={webStyle.div3} onClick={this.handlenavigation}>
                        <ArrowBackIosIcon style={webStyle.arrowColor} />
                        <h4 style={webStyle.backHome}>Back To Home</h4>
                    </div>
                    <h1 style={webStyle.headerCap}>{heading}</h1>
                </div>
                {renderComponent}
            </Box>
        )
    }

    render() {

        return (
            <>
                <div style={webStyle.container}>
                    <MainHeader 
                        handledashprofile={this.handledashprofile} 
                        handlemodalopen={this.handlemodalopen} 
                        data-aos='zoom-in' 
                        navigation={undefined} 
                        handleLogout={this.handleLogout} 
                        handleHeaderRoute={this.handleHeaderRoute} 
                        handledashboard={this.navigateToDashBoard} 
                        type={""} 
                        handleLogin={this.handleLoginBtn} 
                        handleToLandingPage={this.handleToLandingPageTo} 
                    />
                </div>
                <Box>
                    {this.renderPage()}
                    {
                        this.checkIsInfoCalcPage() &&  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '20px', marginTop: '50px', }} >
                            <button onClick={this.handleTaxCalculator} data-test-id='handleTaxCalculator' style={webStyle.calculateBtn}>Calculate</button>

                            <button style={webStyle.resetBtnStyle} onClick={this.handleReset}>Reset</button>
                        </div>
                    }
                </Box>
            </>
        );
    }
}

const webStyle = {
    mainBox: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        flexDirection: 'column' as "column",
        alignItems: 'center'
    },
    mainbox1: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%', flexDirection:
            'column' as "column",
        alignItems: 'center'
    },
    headerCap: {
        color:"#36403B",
        fontSize:"32px",
        fontWeight:"bold" as "bold"
    },
    heading:{
        fontSize: '22px',
        color:"#000000",
    },
    subHeading:{
        fontSize: '18px',
        color:"#000000",
        fontWeight: "normal" as "normal"
    },
    container: {
        height: '100px',
        backgroundColor: '#347152',
        marginBottom: '50px'
    },
    div2: {
        marginBottom: '5px',
        width: '85%',
    },
    div3: {
        height: '20px',
        display: 'flex',
        alignItems: 'center',
    },
    backHome: {
        color: "#347152",
        fontWeight: 'normal' as "normal",
    },
    headerColor: {
        color: "#36403B",
        fontSize: "32px",
        fontWeight: "bold" as "bold"

    },
    arrowColor: {
        color: "#347152"
    },
    btnDiv: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px',
        marginTop: '50px',
    },
    btnStyle: {
        height: '45px',
        width: '170px',
        color: 'white',
        backgroundColor: '#347152',
        fontSize: '16px',
        margin: '10px',
        fontWeight: "bold" as "bold",
        borderRadius: '4px',
        fontFamily: 'sans-serif'
    },
    btnReset:{
        backgroundColor: 'white',
        height: '45px', 
         width: '170px',
          color: '#347152',
        border: '2px solid #347152', 
        fontSize: '16px', 
        margin: '10px',
        fontWeight: 'bold' as "bold",
        borderRadius: '4px', 
        fontFamily: 'sans-serif'
    },
    customBox: {
        marginBottom: '10px', 
        border: '1px solid #d3d3d3', 
        padding: '2px 35px',
        width: '80%',        
        minHeight: "50px",
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderRadius: '4px',
    },
    infoCalcBox: {
        boxSizing: 'border-box' as 'border-box', 
        width: '85%', 
        margin: 'auto', 
        padding: '15px 30px 15px 30px', 
        borderRadius: '10px', 
        whiteSpace: 'pre-line' as 'pre-line', 
        border: '1px solid #ECECEC',
    },
    calculateBtn: { 
        height: '45px', 
        width: '170px', 
        color: 'white', 
        backgroundColor: '#347152', 
        fontSize: '16px', 
        margin: '10px', 
        fontWeight: "bold" as "bold", 
        borderRadius: '4px', 
        fontFamily: 'sans-serif', 
        border: 'none' 
    },
    resetBtnStyle: {
        height: '45px', 
        width: '170px', 
        backgroundColor: 'white',
        color: '#347152',
        fontSize: '16px', 
        margin: '10px',
        borderRadius: '4px', 
        fontFamily: 'sans-serif',
        fontWeight: 'bold' as 'bold', 
        border: '2px solid #347152',
    },
    radioGroupWrapper: {
        gap: "20px"
    },
    marginRight: {
        marginRight: "20px",
    },
    marginRightNone: {
        marginRight: 0,
    },
}

const StyledSelect = withStyles((theme) => ({
    root: {
        border: '1px solid grey',
        borderRadius: '4px',
        width: '143px',
        padding: '5px',
        paddingLeft: '10px',
        height: '20px',
        paddingTop: '6px',
        fontSize: '14px',
        '& .MuiSvgIcon-root': {
            marginRight: '10px',
            color: "red !important",
        },
    },
}))(Select);

const StyledRadio = styled(Radio)({
    '&.Mui-checked': {
        color: 'rgb(52, 113, 82)',
    },
});

const arrowDownIcon = styled(ExpandMoreIcon)({
    marginRight: "10px"
})

// Customizable Area End
